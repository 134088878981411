
  /* eslint-disable no-undef */
  import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Location from '@redooo/shared/dist/types/ui/location';
  import FormField from '../components/FormField.vue';
  import { sharedStore, shopStore } from '../store';
  import { getCustomerWasteTypeContainerPrices } from '../services/api/prices';
  import { trackSelectedZipCode } from '../services/gtm';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';
  import logger from '@redooo/shared/dist/services/logging';

  @Component({
    components: {
      FormField,
      SiteButton,
      ValidationObserver,
      ValidationProvider,
      IconBase,
    },
  })
  export default class ZipCodeAutocomplete extends Vue {
    location = '';
    select: any | string = '';
    searchResults: { text: string; value: google.maps.places.AutocompletePrediction }[] = [];
    autoCompleteService: google.maps.places.AutocompleteService | null = null;
    currentPositionBounds: google.maps.LatLngBounds | undefined = undefined;
    googleSessionToken: google.maps.places.AutocompleteSessionToken | string = '';
    detailService: any = null;
    loading = false;
    externalJSLoaded = false;
    searchTimerId?: number;

    @Prop()
    isBigBag!: boolean;

    $refs!: {
      validationProvider: InstanceType<typeof ValidationProvider>;
    };

    head() {
      return {
        script: [
          {
            hid: 'GooglePlaces',
            src: `https://maps.googleapis.com/maps/api/js?key=${this.$config.googleApiKey}&libraries=places&language=de`,
            defer: true,
            skip: this.isExternalResourceLoaded,
            callback: () => {
              this.externalJSLoaded = true;
              this.initializeServices();
            },
          },
        ],
      };
    }

    confirmStep() {
      sharedStore.updateLastPassedShopStep(0);
      trackSelectedZipCode(this.$gtm, this.selectedLocation);
      // navigate(event);
    }

    mounted() {
      // use next tick here to be sure all child components are rendered
      this.$nextTick(function () {
        this.initializeServices();
      });
    }

    @Watch('location')
    onSearchChange(val: string, valOld: string) {
      if (val && val !== valOld && val.length >= 3 && this.autoCompleteService && this.location !== this.select?.description) {
        this.fetchEntriesDebounced();
      }

      if (val.length === 0) {
        this.$refs.validationProvider.setErrors([this.$i18n.t('global.forms.errorMessages.required') as string]);
      }
    }

    get selectedLocation() {
      return shopStore.selectedLocation;
    }

    get isExternalResourceLoaded() {
      return this.externalJSLoaded || this.isGooglePlacesApiAvailable;
    }

    get isGooglePlacesApiAvailable() {
      let result = false;
      try {
        if (window.google.maps.places) {
          result = true;
        }
      } catch (e) {
        result = false;
      }
      return result;
    }

    updateLocation(zipCode: string, state?: string, city?: string, street?: string, streetNo?: string) {
      const timestamp = Date.now();

      const selectedLocation: Location = {
        zipCode,
        city,
        state: state || '',
        street,
        streetNo,
        timestamp,
      };

      shopStore.resetAll();
      shopStore.updateSelectedLocation(selectedLocation);
    }

    initializeServices() {
      if (!this.isExternalResourceLoaded || !window.google) return;
      this.autoCompleteService = !this.autoCompleteService ? new window.google.maps.places.AutocompleteService() : this.autoCompleteService;
      this.googleSessionToken = !this.googleSessionToken ? new window.google.maps.places.AutocompleteSessionToken() : this.googleSessionToken;
    }

    geolocate() {
      const that = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy,
            });
            that.currentPositionBounds = circle.getBounds() || undefined;

            // check current position & and prefill select field
            /*
            const geoCoder = new window.google.maps.Geocoder();
            geoCoder.geocode({ location: geolocation }, (data) => {
              if (data && data.length !== 0) {
                that.select = data[0].formatted_address;
              }
            });
            */
          },
          undefined,
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
      }
    }

    onSelectChanged(option: { text: string; value: google.maps.places.AutocompletePrediction }) {
      if (option && option.value) {
        this.selectLocation(option.value);
      } else {
        this.searchResults = [];
      }
    }

    selectLocation(event: google.maps.places.AutocompletePrediction) {
      const that = this;
      if (!this.detailService) {
        this.detailService = new google.maps.places.PlacesService(document.createElement('div'));
      }

      // search input cleared?
      if (!event || !event.place_id) return;

      this.detailService.getDetails(
        { placeId: event.place_id, fields: ['address_components'], sessionToken: this.googleSessionToken },
        async (data: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && data.address_components) {
            const state = this.getAddressComponentType('administrative_area_level_1', data.address_components);
            const postalCode = this.getAddressComponentType('postal_code', data.address_components);
            const city = this.getAddressComponentType('locality', data.address_components);
            const streetNo = this.getAddressComponentType('street_number', data.address_components);
            const street = this.getAddressComponentType('route', data.address_components);

            if (!postalCode) {
              this.$refs.validationProvider.setErrors([this.$i18n.t('global.forms.errorMessages.googleNoZipCode') as string]);
              return;
            }

            this.updateLocation(postalCode.long_name, state?.short_name, city?.long_name, street?.long_name, streetNo?.long_name);

            const isBigBag: boolean = !!this.isBigBag;
            this.googleSessionToken = new window.google.maps.places.AutocompleteSessionToken();
            shopStore.setIsBigBagOrder(isBigBag);

            const prices = await getCustomerWasteTypeContainerPrices(that.$sentryLogger, that.$axios, postalCode.long_name, isBigBag);
            if (isBigBag && prices?.length === 0) {
              this.$refs.validationProvider.setErrors([this.$i18n.t('global.forms.errorMessages.noBigBagForZipCode') as string]);
              return;
            }

            if (prices) {
              // remove validationProvider errors
              this.$refs.validationProvider.setErrors([]);
              shopStore.updateCustomerWasteTypeContainerPrices(prices);
            }

            // check for a precise street result

            // const geoCoder = new window.google.maps.Geocoder();
            // geoCoder.geocode(
            //   { placeId: event.place_id },
            //   (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            //     logger.log(results, status);
            //     if (results && results.length !== 0) {
            //       logger.log(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            //       logger.log('is precise street address', results[0].types.includes('street_address'));
            //       logger.log(this.getAddressComponentType('route', results[0].address_components));
            //       logger.log(this.getAddressComponentType('street_number', results[0].address_components));
            //       logger.log(this.getAddressComponentType('postal_code', results[0].address_components));
            //       logger.log(this.getAddressComponentType('locality', results[0].address_components));
            //     }
            //   }
            // );
          }
        }
      );
    }

    getAddressComponentType(addressType: string, addressComponents: google.maps.GeocoderAddressComponent[]) {
      return addressComponents.find(function (component) {
        return component.types.includes(addressType);
      });
    }

    fetchEntriesDebounced() {
      window.clearTimeout(this.searchTimerId);
      this.searchTimerId = window.setTimeout(() => {
        this.loading = true;
        if (!this.autoCompleteService) return;
        const getPlacePredictionsRequest = {
          input: this.location,
          types: ['geocode'],
          bounds: this.currentPositionBounds,
          componentRestrictions: { country: 'de' },
          sessionToken: this.googleSessionToken,
        };
        try {
          this.autoCompleteService.getPlacePredictions(getPlacePredictionsRequest, this.displaySuggestions);
        } catch (error) {
          this.$sentryLogger('Google - getPlacePredictions', { originError: error, request: getPlacePredictionsRequest });
        }
      }, 750); /* 750ms throttle */
    }

    displaySuggestions(predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) {
      this.loading = false;
      if (!predictions || status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.map((prediction) => {
        return { text: prediction.description, value: prediction };
      });
    }
  }
