
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { ValidationProvider } from 'vee-validate';

  @Component({
    components: { ValidationProvider },
  })
  export default class FormField extends Vue {
    @Prop()
    label?: string;

    @Prop()
    rules?: string;

    @Prop({ default: 'aggressive' })
    mode?: string;

    @Prop({ default: false })
    required!: boolean;

    // disables vee-validate even if required prop is set, useful if a field should still be marked as required but not validated.
    @Prop({ default: false })
    preventValidation!: boolean;

    get generatedRules() {
      if (this.rules) {
        return this.required ? 'required|' + this.rules : this.rules;
      } else {
        return 'required';
      }
    }
  }
