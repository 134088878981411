
  import { Vue, Component, Prop } from 'nuxt-property-decorator';
  import { Image } from '@redooo/shared/dist/types/content/image';
  import ContentfulLink from '../components/ContentfulLink.vue';
  import ResponsiveMedia from '../components/shared/ResponsiveMedia.vue';
  import SiteButton from '../components/shared/SiteButton.vue';
  import TrustedShopsBadge from '../components/TrustedShopsBadge.vue';
  import ZipCodeAutocomplete from './ZipCodeAutocomplete.vue';

  interface BtnInfo {
    linkCaption: string;
    linkNavigationRefId: string;
  }

  @Component({
    components: {
      ContentfulLink,
      ResponsiveMedia,
      SiteButton,
      TrustedShopsBadge,
      ZipCodeAutocomplete,
    },
  })
  export default class Stage extends Vue {
    @Prop()
    colorScheme!: 'black' | 'blue';

    @Prop()
    displayZipCodeInput!: boolean;

    @Prop()
    headline!: string;

    @Prop()
    copy!: string;

    @Prop()
    imageData?: Image;

    @Prop()
    buttons?: BtnInfo[];

    @Prop()
    isBigBag!: boolean;

    @Prop({ default: 'h1' })
    headlineTag!: 'h1' | 'h2' | 'h3';

    @Prop({ default: true })
    hasMarginTop!: boolean;

    get generatedColorScheme() {
      return this.colorScheme === 'black' ? 'primary-blue-dark' : 'primary-gradient-blue';
    }

    btnIndexToPriority(index: number) {
      const indexToPriority: { [key: number]: string } = {
        0: 'primary',
        1: 'secondary-outline',
        2: 'tertiary',
      };

      return indexToPriority[index] ? indexToPriority[index] : 'secondary-outline';
    }
  }
