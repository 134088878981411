import { WasteType } from '@redooo/shared/dist/types/services/wasteType';
import { OrderItemExtended } from '@redooo/shared/dist/types/ui/orderItem';
import { CreatePrivateCustomerOrderRequest } from '@redooo/shared/dist/types/order/createPrivateCustomerOrderRequest';
import Location from '@redooo/shared/dist/types/ui/location';
import { OrderFormData } from '@redooo/shared/dist/types/ui/orderFormData';
import { PartialDeep } from 'type-fest';
import { PrivateCustomerOrderSuccessfulResponse } from '@redooo/shared/dist/types/order/privateCustomerOrderSuccessfulResponse';
import { GoogleTagManager } from '../../types/vue-shim';

// ZipCodeAutocomplete-Component
export const trackSelectedZipCode = ($gtm: GoogleTagManager, location: Location): void => {
  $gtm.push({
    event: 'red_step1_postcode_selected',
    red_step1_postcode: location.zipCode,
  });
};

// Step-1 page
export const trackSelectedWasteType = ($gtm: GoogleTagManager, wasteType: WasteType): void => {
  $gtm.push({
    event: 'red_step2_wastetype_selected',
    red_step2_wastetype: wasteType.name,
    red_step2_wastetypeId: wasteType.externalQualifier,
  });
};

// Step-2 page
export const trackAddToCart = ($gtm: GoogleTagManager, orderItem: OrderItemExtended): void => {
  $gtm.push({
    event: 'addToCart',
    ecommerce: {
      items: [
        {
          currency: 'EUR',
          item_category2: orderItem.wasteTypeInfo.externalQualifier,
          item_category3: orderItem.wasteTypeInfo.name,
          item_category: orderItem.containerTypeInfo.externalQualifier,
          item_id: orderItem.containerTypeInfo.externalQualifier,
          item_name: orderItem.containerTypeInfo.name,
          price: orderItem.priceInfo.customerPriceMwSt / 100,
          quantity: 1,
        },
      ],
    },
  });

  $gtm.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1 },
      },
    },
  });
};

// Step-3 page - trash-icon top-right corner
export const trackRemoveFromCart = ($gtm: GoogleTagManager, orderItem: OrderItemExtended): void => {
  $gtm.push({
    event: 'removeFromCart',
    ecommerce: {
      items: [
        {
          currency: 'EUR',
          item_category2: orderItem.wasteTypeInfo.externalQualifier,
          item_category3: orderItem.wasteTypeInfo.name,
          item_category: orderItem.containerTypeInfo.externalQualifier,
          item_id: orderItem.containerTypeInfo.externalQualifier,
          item_name: orderItem.containerTypeInfo.name,
          price: orderItem.priceInfo.customerPriceMwSt / 100,
          quantity: 1,
        },
      ],
    },
  });
};

// Step-3 page
export const trackFinishedStep3 = ($gtm: GoogleTagManager): void => {
  $gtm.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2 },
      },
    },
  });
};

// Step-4 page
export const trackFinishedStep4 = ($gtm: GoogleTagManager, orderFormData: PartialDeep<OrderFormData>): void => {
  if (orderFormData.customerType) {
    $gtm.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: orderFormData?.customerType.toLowerCase() + ' customer' },
        },
      },
    });
  }
  if (orderFormData.publicGround !== undefined) {
    $gtm.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 4, option: (orderFormData.publicGround ? 'public' : 'private') + ' ground' },
        },
      },
    });
  }
};

// PaymentContainerComponent
export const trackFinishedStep5 = ($gtm: GoogleTagManager, orderRequest: CreatePrivateCustomerOrderRequest): void => {
  $gtm.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 5, option: orderRequest.privateCustomerOrder.paymentType?.toLowerCase() },
      },
    },
  });
};

// PaymentContainerComponent
export const trackOrderSubmitted = ($gtm: GoogleTagManager, order: PrivateCustomerOrderSuccessfulResponse): void => {
  $gtm.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: order.orderNumber || order.clientOrderUuid, // Transaction ID. Required for purchases and refunds.
      affiliation: order.refererId || '', // capture affiliation when user came via an affiliate link
      value: order.priceBrutto / 100, // Total transaction value (incl. tax and shipping)
      tax: order.priceTax / 100,
      customerType: order.customertype.toLowerCase(), // Dimension for customer type (private or commercial)
      serviceLocation: (order.publicGround ? 'public' : 'private') + ' ground', // Dimension for service location (private ground or public ground)
      zipCode: order.serviceLocationPrivateCustomer.zipCode, // Dimension for ZipCode that was entered in Step 1
      payment_type: order.paymentType.toLowerCase(),
      items: [
        {
          // List of productFieldObjects.
          currency: 'EUR',
          item_category2: order.wastetype.externalQualifier,
          item_category3: order.wastetype.name, // wastetype
          item_category: order.containertype.externalQualifier,
          item_id: order.containertype.externalQualifier,
          item_name: order.containertype.name, // Name or ID is required = Containername
          price: order.priceBrutto / 100,
          quantity: 1,
        },
      ],
    },
  });
};
