import { CustomerWasteTypeContainerPrice } from '@redooo/shared/dist/types/services/customerWasteTypeContainerPrice';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { plainToClass } from 'class-transformer';

import { shopStore } from '../../../utils/store-accessor';

const getCustomerWasteTypeContainerPrices = async (
  $sentryLogger: any,
  $axios: NuxtAxiosInstance,
  zipCode: string,
  isBigBag?: boolean
): Promise<CustomerWasteTypeContainerPrice[] | undefined> => {
  let response: any;
  try {
    shopStore.updateHttpApiLoadingErrorOccurred(false);
    const uri = isBigBag ? 'privateCustomerBigBagPrices' : 'privateCustomerPrices';
    // contentful waste types id / contentful container types id / locale
    response = await $axios.$get(`/pricing/${uri}/${zipCode}`);
  } catch (error) {
    shopStore.updateHttpApiLoadingErrorOccurred(true);
    $sentryLogger('Getting customer wastetype prices from service', { originError: error });
  }

  if (response?.data) {
    return plainToClass(CustomerWasteTypeContainerPrice, response.data as CustomerWasteTypeContainerPrice[]);
  } else {
    $sentryLogger('response.data not set in getCustomerWasteTypeContainerPrices', { response });
    return undefined;
  }
};

export { getCustomerWasteTypeContainerPrices };
