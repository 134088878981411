
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { Image } from '@redooo/shared/dist/types/content/image';

  interface ImageDimensions {
    width: number;
    height: number;
  }

  @Component
  export default class ResponsiveMedia extends Vue {
    @Prop()
    data!: Image;

    @Prop({ default: () => {} })
    resolutions?: {
      desktop: ImageDimensions;
      tablet: ImageDimensions;
      mobile: ImageDimensions;
    };

    @Prop({ default: false })
    displayCaption!: Boolean;

    imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/webp'];

    videoTypes = ['video/mov', 'video/quicktime', 'video/avi', 'video/mp4', 'video/webm', 'video/ogg', 'video/ogv', 'video/ogm'];

    audioTypes = ['audio/mpeg'];

    docTypes = ['application/pdf'];

    isImage(contentType: string) {
      return this.imageTypes.includes(contentType);
    }

    isVideo(contentType: string) {
      return this.videoTypes.includes(contentType);
    }

    isAudio(contentType: string) {
      return this.audioTypes.includes(contentType);
    }

    isPdf(contentType: string) {
      return this.docTypes.includes(contentType);
    }

    get imageSizes() {
      return [
        { title: 'desktop', breakpoint: 1024, width: this.resolutions?.desktop?.width, height: this.resolutions?.desktop?.height },
        { title: 'tablet', breakpoint: 768, width: this.resolutions?.tablet?.width, height: this.resolutions?.tablet?.height },
        { title: 'mobile', width: this.resolutions?.mobile?.width, height: this.resolutions?.mobile?.height },
      ];
    }
  }
