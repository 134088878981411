
  import { Vue, Component, Prop } from 'nuxt-property-decorator';
  import { getLocalizedSiteConfiguration } from '@redooo/shared/dist/helpers/content';
  import { ButtonPriority } from '@redooo/shared/dist/types/ui/buttonPriority';
  import { getLinkForNavigationItemRefId } from '../utils/navigation';
  import { siteConfigStore } from '../store';
  import SiteButton from '../components/shared/SiteButton.vue';

  @Component({
    components: {
      SiteButton,
    },
  })
  export default class ContentColumns extends Vue {
    @Prop()
    linkNavigationRefId!: string;

    @Prop({ default: 'secondary-outline' })
    priority!: ButtonPriority;

    get siteConfiguration() {
      return getLocalizedSiteConfiguration(siteConfigStore.configurations, this.$i18n.locale);
    }

    get linkObject() {
      if (this.linkNavigationRefId) {
        return getLinkForNavigationItemRefId(this.linkNavigationRefId, this.siteConfiguration?.mainNavigation);
      }
    }
  }
