
  import { Vue, Component } from 'nuxt-property-decorator';

  @Component
  export default class TrustedShopsBadge extends Vue {
    isTrustedShopsBadgeLoaded = false;

    head() {
      return {
        script: [
          {
            hid: 'trustedShops',
            src: '/trusted-shops/trusted-shops.js',
            defer: true,
            skip: this.isTrustedShopsBadgeLoaded,
            callback: () => {
              this.isTrustedShopsBadgeLoaded = true;
            },
          },
        ],
      };
    }
  }
